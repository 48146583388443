import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { firstValueFrom, map, Observable, of } from 'rxjs';
import { UserService } from '../services/user.service';
@Injectable({
    providedIn: 'root'
})
export class AuthGuard {
    user$: Observable<any> = this.userService.retrieveUser();
    
    constructor(
        private router: Router,
        private userService: UserService
    ) { }

    canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
        if (route.routeConfig.path === '') {
            return of(true);
        }

        const routesWithoutLogin = ['login', 'register'];

        return this.user$.pipe(
            map((user: any) => {
                if (routesWithoutLogin.includes(route.routeConfig.path)) {
                    if (user) {
                        this.router.navigateByUrl('/home');
                    } else {
                        return true;
                    }
                } else {
                    if (user) {
                        return true;
                    } else {
                        this.router.navigateByUrl('/login');
                    }
                }
            })
        );
    }
}
