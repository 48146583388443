interface CodeGenerationInformation {
    id: any;
    selectTemplates: any;
    inputsForm: any;
    selectProvider: any;
    selectProduct: any;
    listAreas: any;
}

export interface DocumentCodeGenerationStrategy {
    generateCode(data: CodeGenerationInformation): string;
}

export class ManualDocumentCodeGenerationStrategy implements DocumentCodeGenerationStrategy {
    generateCode(_: CodeGenerationInformation): string {
        return 'Por asignar';
    }
}

export class FolderRouteDocumentCodeGenerationStrategy implements DocumentCodeGenerationStrategy {
    generateCode(data: CodeGenerationInformation): string {
        console.log(data)

        let automaticDocumentCode = '';
        let findTemplate = data.selectTemplates.find(element => element.id == data.inputsForm.document_template_id);
        if (findTemplate == undefined){
            findTemplate = '';
        } else {
            findTemplate = findTemplate.type.substring(0, 3);
            findTemplate = findTemplate.toUpperCase();
            findTemplate += '-';
        }
        if (data.inputsForm.document_location_id == 0){
            // Documento externo
            let findProvider = data.selectProvider.find(element => element.id == data.inputsForm.document_provider_id);
            if(findProvider.name == 'San Miguel Allende'){
                findProvider = 'SMS';
            } else if(findProvider.name == 'Instant Food'){
                findProvider = 'IFM';
            } else if(findProvider.name == 'Alimentos Mayorga'){
                findProvider = 'AMA';
            } else if(findProvider.name == 'Totofast'){
                findProvider = 'TTF';
            } else if(findProvider.name == 'Nyjisa'){
                findProvider = 'NYJ';
            }else{
                findProvider = findProvider.name.replace(/\s+/g, '');
                findProvider = findProvider.substring(0, 3);
                findProvider = findProvider.toUpperCase();
            }

            let findProduct = data.selectProduct.find(element => element.id == data.inputsForm.document_product_id);
            if (findProduct == undefined){
                findProduct = '';
            } else {
                findProduct = findProduct.name.replace(/\s+/g, '');
                findProduct = findProduct.substring(0, 3);
                findProduct = findProduct.toUpperCase();
                findProduct += '-';
            }
            // automatic_document_code = 'ED-';
            if (data.inputsForm.document_product_type_id == 1){
                // Materia prima
                automaticDocumentCode += 'RM-' + findProvider + '-' + findProduct + findTemplate + data.id;
            } else if (data.inputsForm.document_product_type_id == 2){
                // Producto
                automaticDocumentCode += 'FP-' + findProvider + '-' + findProduct + findTemplate + data.id;
            } else {
                // Paquete
                automaticDocumentCode += 'PK-' + findProvider + '-' + findProduct + findTemplate + data.id;
            }
        } else {
            // Documento interno
            let findArea = data.listAreas.find(element => element.id == data.inputsForm.area_id);
            findArea = findArea.abbreviation;
            automaticDocumentCode += 'SMS-' + findArea + '-' + findTemplate + data.id;
        }
        return automaticDocumentCode;
    }
}