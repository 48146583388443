<div (click)="goPage(notification)" class="notification-item">
    <div class="new-notification-container">
        <div *ngIf="notification.read == 0" class="notification-point">
        </div>
        <div *ngIf="notification.read == 1" style="width: 8px;">
        </div>
    </div>
    <div class="notification-text">
        <div>
            <p *ngIf="notification.type != 'obsolete_diffusion'" class="notification-info" title="{{ notification.name }} {{ notification.notification }}">
                <span class="user-notification-name">{{ notification.user_name }}</span> 
                {{ notification.notification }}
            </p>
            <p *ngIf="notification.type == 'obsolete_diffusion'" class="notification-info" title="{{ notification.notification }}">
                {{ notification.notification }}</p>
        </div>
        <div class="notification-extra">
            <p>{{ notification.folder_route }}</p>
        </div>
        <div class="notification-extra" style="margin-top: 0;">
            <p>{{ notification.user_name }}</p>
            <p class="point-separator"></p>
            <p>{{ notification.date | date : 'dd-MM-yyyy hh:mm aa' }}</p>
        </div>
    </div>
    <div class="profile-image-container">
        <img class="profile-icon" src="{{ (notification.photo != null )? photo_url + notification.from_user_id + '/' + notification.photo : '../../../assets/resources/icons/profile.png' }}" alt="">
    </div>
</div>
<hr width="95%">