import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InsideLayoutModule } from '../layout/inside-layout/inside-layout.module';
import { AuthModule } from '../layout/outside-layout/auth.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './guards/auth-guard.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { EditGuard } from './guards/edit-guard.guard';
import { NgxSpinnerModule } from "ngx-spinner";
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    InsideLayoutModule,
    AuthModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    DateInputsModule,
    NgSelectModule
  ],
  providers: [AuthGuard,
    EditGuard,
    NgxImageCompressService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

declare global{
    interface Navigator{
        msSaveBlob:(blob: Blob,fileName:string) => boolean
        msSaveOrOpenBlob:(blob: Blob,fileName:string) => boolean
    }
}