import { DocumentCodeGenerationStrategy, ManualDocumentCodeGenerationStrategy, FolderRouteDocumentCodeGenerationStrategy } from '../pages/document/create/generate-code/DocumentCodeGenerationStrategies'

enum Permissions {
    READ = 1,
    EDIT = 2,
    DOWNLOAD = 3,
}

interface Configuration {
    business_name: string;
    api_url: string;
    web_url: string;
    show_employee_code: boolean;
    logo: 'default' | 'custom'; // Añadir logos personalizados en assets/resources/logos/custom
    documentCodeGenerationStrategy: DocumentCodeGenerationStrategy;
    supports_external_documents: boolean;
    specific_template_name: boolean;
    users_limit: number | boolean;
    show_master_list: boolean;
    extra_templates: string[];
    diffusion: {
        has_step: boolean;
        permissions?: {
            general: Permissions[];
            specific?: {
                template_name: string;
                permissions: Permissions[];
            }[]
        }
    };
    document_package_enabled: boolean;
    notify_of_approval: number[]; // Ids de usuarios a los que se les notificará la aprobación de un documento
    display_external_documents: boolean;
    menu: {
        panel: boolean;
        documentos?: {
            create: boolean;
            SIGCI: boolean;
            templates: boolean;
            externos: boolean;
            request_changes: boolean;
            indicators: boolean;
            masterlist: boolean;
        };
        auditorias: boolean;
        indicidentes: boolean;
        administracion: boolean;
        mejora_continua: boolean;
        risks: boolean;
        bandeja: boolean;
        roles: boolean;
        directorio: boolean;
    };
    has_institutional_email: {
        has_step: boolean;
        emails?: string[];
    };
    supports_version: boolean;
    support_area_user: boolean;
    send_individual_email: boolean;
    can_edit_in_approval: boolean;
    can_edit_skills: boolean;
    has_date_expiration: {
        has_step: boolean;
        date?: number;
    };
    has_user_diffusion_confirmation: boolean;
    can_edit_creation_date: boolean;
    has_complete_date_info: boolean;
}

// const config: Configuration = {
//     business_name: 'blen',
//     api_url: 'https://api.blen.m3consulting.com.mx/',
//     web_url: 'https://blen.m3consulting.com.mx/',
//     show_employee_code: true,
//     logo: 'default',
//     documentCodeGenerationStrategy: new ManualDocumentCodeGenerationStrategy(),
//     supports_external_documents: false,
//     specific_template_name: true,
//     users_limit: false,
//     extra_templates: [
//         'SIGCI USA'
//     ],
//     show_master_list: true,
//     diffusion: {
//         has_step: false,
//         permissions: {
//             general: [Permissions.READ],
//             specific: [
//                 {
//                     template_name: 'Formatos',
//                     permissions: [Permissions.READ, Permissions.DOWNLOAD]
//                 },
//             ]
//         }
//     },
//     document_package_enabled: false,
//     notify_of_approval: [43],
//     display_external_documents: false,
//     menu: {
//         panel: true,
//         documentos: {
//             create: true,
//             SIGCI: true,
//             templates: false,
//             externos: true,
//             request_changes: true,
//             indicators: true,
//             masterlist: true,
//         },
//         auditorias: false,
//         indicidentes: false,
//         administracion: false,
//         mejora_continua: false,
//         risks: false,
//         bandeja: true,
//         roles: true,
//         directorio: true,
//     },
//     has_institutional_email: {
//         has_step: true,
//         emails: ['blen', 'm3consulting', 'blackfor'],
//     },
//     supports_version: false,
//     support_area_user: false,
//     send_individual_email: true,
//     can_edit_in_approval: false,
//     can_edit_skills: false,
//     has_date_expiration: {
//         has_step: false
//     },
//     has_user_diffusion_confirmation: false,
//     can_edit_creation_date: true,
//     has_complete_date_info: true,
// };

// const config: Configuration = {
//     business_name: 'somos',
//     api_url: 'https://api.somos.m3consulting.com.mx/',
//     web_url: 'https://somos.m3consulting.com.mx/',
//     show_employee_code: false,
//     logo: 'custom',
//     documentCodeGenerationStrategy: new FolderRouteDocumentCodeGenerationStrategy(),
//     supports_external_documents: true,
//     specific_template_name: false,
//     users_limit: 22,
//     extra_templates: [
//         'Arte',
//         'Documentos externos',
//         'Explosión de materiales',
//         'Formulación',
//         'Plantillas',
//         'Presupuestos',
//         'Pruebas',
//         'Tabla nutrimental'
//     ],
//     show_master_list: false,
//     diffusion: {
//         has_step: true
//     },
//     document_package_enabled: true,
//     notify_of_approval: [],
//     display_external_documents: false,
//     menu:{
//         panel: true,
//         documentos: {
//             create: true,
//             SIGCI: true,
//             templates: true,
//             externos: true,
//             request_changes: true,
//             indicators: true,
//             masterlist: true,
//         },
//         auditorias: false,
//         indicidentes: false,
//         administracion: false,
//         mejora_continua: false,
//         risks: false,
//         bandeja: true,
//         roles: true,
//         directorio: true,
//     },
//     has_institutional_email: false,
//     supports_version: false,
//     has_user_diffusion_confirmation: false,
// };

const config: Configuration = {
    business_name: 'test',
    api_url: 'https://api.test.m3consulting.com.mx/',
    web_url: 'https://test.m3consulting.com.mx/',
    show_employee_code: true,
    logo: 'default',
    documentCodeGenerationStrategy: new ManualDocumentCodeGenerationStrategy(),
    supports_external_documents: false,
    specific_template_name: true,
    users_limit: false,
    extra_templates: [
        'SIGCI USA'
    ],
    show_master_list: true,
    diffusion: {
        has_step: true,
        permissions: {
            general: [Permissions.READ],
            specific: [
                {
                    template_name: 'Formatos',
                    permissions: [Permissions.READ, Permissions.DOWNLOAD]
                },
            ]
        }
    },
    document_package_enabled: true,
    notify_of_approval: [],
    display_external_documents: false,
    menu: {
        panel: true,
        documentos: {
            create: true,
            SIGCI: true,
            templates: true,
            externos: true,
            request_changes: true,
            indicators: true,
            masterlist: true,
        },
        auditorias: true,
        indicidentes: true,
        administracion: true,
        mejora_continua: true,
        risks: true,
        bandeja: true,
        roles: true,
        directorio: true,
    },
    has_institutional_email: {
        has_step: false,
    },
    supports_version: false,
    support_area_user: false,
    send_individual_email: false,
    can_edit_in_approval: true,
    can_edit_skills: true,
    has_date_expiration: {
        has_step: false,
        date: 24,
    },
    has_user_diffusion_confirmation: true,
    can_edit_creation_date: true,
    has_complete_date_info: false,
};

// const config: Configuration = {
//     business_name: 'ibarra',
//     api_url: 'https://api.ibarra.m3consulting.com.mx/',
//     web_url: 'https://ibarra.m3consulting.com.mx/',
//     show_employee_code: true,
//     logo: 'default',
//     documentCodeGenerationStrategy: new ManualDocumentCodeGenerationStrategy(),
//     supports_external_documents: false,
//     specific_template_name: false,
//     users_limit: false,
//     extra_templates: [],
//     show_master_list: true,
//     diffusion: {
//         has_step: true,
//         permissions: {
//             general: [Permissions.READ],
//         }
//     },
//     document_package_enabled: true,
//     notify_of_approval: [],
//     display_external_documents: false,
//     menu:{
//         panel: true,
//         documentos: {
//             create: true,
//             SIGCI: true,
//             templates: true,
//             externos: true,
//             request_changes: true,
//             indicators: true,
//             masterlist: true,
//         },
//         auditorias: false,
//         indicidentes: false,
//         administracion: false,
//         mejora_continua: false,
//         risks: false,
//         bandeja: true,
//         roles: true,
//         directorio: true,
//     },
//     has_institutional_email: {
//         has_step: true,
//         emails: ['ibarra', 'fruco', ' novarmex', 'm3consulting', 'blackfor'],
//     },
//      supports_version: true,
//      support_area_user: true,
//      send_individual_email: true,
//      can_edit_in_approval: true,
//      can_edit_skills: false,
//      has_date_expiration: {
//         has_step: false,
//         date: 24,
//     },
//     has_user_diffusion_confirmation: false,
//     can_edit_creation_date: true,
//     has_complete_date_info: false,
// };

// const config: Configuration = {
//     business_name: 'novamex',
//     api_url: 'https://api.novamex.m3consulting.com.mx/',
//     web_url: 'https://novamex.m3consulting.com.mx/',
//     show_employee_code: true,
//     logo: 'default',
//     documentCodeGenerationStrategy: new ManualDocumentCodeGenerationStrategy(),
//     supports_external_documents: false,
//     specific_template_name: false,
//     users_limit: false,
//     extra_templates: [],
//     show_master_list: true,
//     diffusion: {
//         has_step: true,
//         permissions: {
//             general: [Permissions.READ],
//         }
//     },
//     document_package_enabled: true,
//     notify_of_approval: [],
//     display_external_documents: false,
//     menu:{
//         panel: true,
//         documentos: {
//             create: true,
//             SIGCI: true,
//             templates: true,
//             externos: true,
//             request_changes: true,
//             indicators: true,
//             masterlist: true,
//         },
//         auditorias: false,
//         indicidentes: false,
//         administracion: false,
//         mejora_continua: false,
//         risks: false,
//         bandeja: true,
//         roles: true,
//         directorio: true,
//     },
//     has_institutional_email: {
//         has_step: true,
//         emails: ['ibarra', 'fruco', ' novarmex', 'm3consulting', 'blackfor'],
//     },
//      supports_version: true,
//      support_area_user: true,
//      send_individual_email: true,
//      can_edit_in_approval: true,
//      can_edit_skills: false,
//      has_date_expiration: {
//         has_step: false,
//         date: 24,
//     },
//     has_user_diffusion_confirmation: false,
//     can_edit_creation_date: true,
//     has_complete_date_info: false,
// };
export default config;