import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom, map, Observable, take } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class EditGuard {
    user$: Observable<any> = this.userService.retrieveUser();

    constructor(
        private router: Router,
        private userService: UserService
    ) { }

    canActivate(): Observable<boolean> {
        return this.user$.pipe(
            map((user: any) => {
                if (user) {
                    if (user.role_id === 1) {
                        return true;
                    } else {
                        for (const module of user.modules) {
                            switch (module.module_id) {
                                case 1:
                                    if (module.user_module_permission.find(permission => permission.permission_id === 2)){
                                        return true;
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }
                        this.router.navigateByUrl('/home');
                    }
                } else {
                    return false;
                }
            })
        );
    }
}
