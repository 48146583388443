import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent {
    @Input() notification: any;
    @Output() closeSidebarEvent = new EventEmitter<void>();

    public photo_url = AppConfig.api_url + "public/uploads/user/";

    constructor(
        private router: Router,
        private notificationService: NotificationService
    ) { }

    goPage(notification) {
        if (notification.type == 'document_obsolete' || notification.type == 'obsolete_diffusion') {
            return;
        }
        if (notification.page != "/training" && notification.page != "/task" && notification.page != "/user-request" && notification.page != "/document") {
            if(notification.type == 'document_request_obsolete'){
                this.router.navigateByUrl(notification.page + notification.params + ";notificacion_id=" + notification.id + ";obsolete=1");
            }
            else {
                this.router.navigateByUrl(notification.page + notification.params + ";notification_id=" + notification.id);
            }
        } else {
            this.router.navigateByUrl(notification.page);
        }

        this.notificationService.saveNotification({ id: notification.id, read: "1" }).subscribe(return_data => {
            if (return_data.success) {
                if (notification.page != "/training" && notification.page != "/task" && notification.page != "/user-request" && notification.page != "/document") {
                    console.log(notification.page);
                    
                    this.router.navigateByUrl(notification.page + notification.params + ";notification_id=" + notification.id);
                } else {
                    this.router.navigateByUrl(notification.page);
                }
                // TODO - Update notification list in sidebar 
            }
        }, error => {
            console.log(error);
        });

        this.closeSidebarEvent.emit();
    }


}
