import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsSidebarComponent } from './notifications-sidebar.component';
import { NotificationItemComponent } from './notification-item/notification-item.component';

@NgModule({
  declarations: [NotificationsSidebarComponent, NotificationItemComponent],
  imports: [
    CommonModule
  ],
  exports: [
    NotificationsSidebarComponent,
  ]
})
export class NotificationsSidebarModule{ }
